import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { TStore } from '../redux/store';
import { checkIsDateBankHolidayOrWeekend } from '../lib/utils/bankHolidays';
import config from '../config/config.json';

function FirstPaymentDateCard(coverStartDate: string, preferredDay: number) {
  const coverStartDateDayJs = dayjs(coverStartDate);
  const today = dayjs();
  const dateForward = today.add(1, 'day');
  const preferredDatePlusOneMonth = coverStartDateDayJs.set('date', preferredDay).add(1, 'month');
  const isCoverStartDateInFutureMonth = coverStartDateDayJs.isAfter(today, 'month');

  if (preferredDay < coverStartDateDayJs.date()) {
    return preferredDatePlusOneMonth.format('Do MMM YYYY');
  }

  if (!isCoverStartDateInFutureMonth && today.date() === preferredDay) {
    return dateForward.format('Do MMM YYYY');
  }

  return coverStartDateDayJs.set('date', preferredDay).format('Do MMM YYYY');
}

function FirstPaymentDateDD(coverStartDate: string, preferredDay: number) {
  const isDDPayment7DayOn = String(config.FEATURES.DD_PAYMENT_7DAY) === 'true';
  const coverStartDateDayJs = dayjs(coverStartDate);
  const purchaseDate = dayjs();

  let dateForward;
  if (isDDPayment7DayOn) {
    let sevenWorkDaysFromToday = dayjs(purchaseDate);
    let workDayCounter = 0;
    while (workDayCounter < 6) {
      sevenWorkDaysFromToday = sevenWorkDaysFromToday.add(1, 'day');
      if (!checkIsDateBankHolidayOrWeekend(sevenWorkDaysFromToday.format('YYYY-MM-DD'))) {
        workDayCounter++;
      }
    }

    // if coverage start day is same as purchase day then assume dateForward will be 7 workdays from today
    // if coverage start day is between purchase day and 7 workdays from today then assume dateForward will be 7 workdays from today
    // if coverage start day is after 7 workdays from purchase day then assume dateForward will be same as coverage start day
    dateForward = coverStartDateDayJs.isAfter(sevenWorkDaysFromToday, 'days')
      ? coverStartDateDayJs
      : sevenWorkDaysFromToday;
  } else {
    dateForward = dayjs(coverStartDate).add(14, 'day');
  }

  const prefDateThisMonth = coverStartDateDayJs.set('date', preferredDay);
  const preferredDatePlusOneMonth = coverStartDateDayJs.set('date', preferredDay).add(1, 'month');

  if (preferredDay < coverStartDateDayJs.date()) {
    return preferredDatePlusOneMonth.isBefore(dateForward, 'days')
      ? dateForward.format('Do MMM YYYY')
      : preferredDatePlusOneMonth.format('Do MMM YYYY');
  }

  if (prefDateThisMonth.isBefore(dateForward, 'days')) {
    return dateForward.format('Do MMM YYYY');
  }

  return prefDateThisMonth.format('Do MMM YYYY');
}

export function useGetPaymentDates(regularPaymentDate?: string) {
  const coverStartDate = useSelector((state: TStore) => state.quoteState.quote.coverStartDate);
  const paymentMethod = useSelector((state: TStore) => state.policyState.policy.selectedPaymentMethod);
  const isCard = paymentMethod === 'Card';
  const preferredDay =
    regularPaymentDate && regularPaymentDate?.length > 0
      ? parseInt(regularPaymentDate, 10)
      : dayjs(coverStartDate).date();

  function SubsequentPaymentDay() {
    if (regularPaymentDate) {
      return dayjs().date(parseInt(regularPaymentDate, 10)).format('Do').toString();
    }

    const coverStartDateDayJs = dayjs(coverStartDate).subtract(1, 'months');
    return coverStartDateDayJs.set('date', preferredDay).format('Do').toString();
  }

  const FirstPaymentDate = () =>
    isCard ? FirstPaymentDateCard(coverStartDate, preferredDay) : FirstPaymentDateDD(coverStartDate, preferredDay);

  return { FirstPaymentDate, SubsequentPaymentDay };
}
