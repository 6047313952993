import dayjs from 'dayjs';

export const bankHolidays: string[] = [
  '2023-04-07T00:00:00',
  '2023-04-10T00:00:00',
  '2023-05-01T00:00:00',
  '2023-05-08T00:00:00',
  '2023-05-29T00:00:00',
  '2023-08-28T00:00:00',
  '2023-12-25T00:00:00',
  '2023-12-26T00:00:00',
  '2024-01-01T00:00:00',
  '2024-03-29T00:00:00',
  '2024-04-01T00:00:00',
  '2024-05-06T00:00:00',
  '2024-05-27T00:00:00',
  '2024-08-26T00:00:00',
  '2024-12-25T00:00:00',
  '2024-12-26T00:00:00',
  '2025-01-01T00:00:00',
  '2025-04-18T00:00:00',
  '2025-04-21T00:00:00',
  '2025-05-05T00:00:00',
  '2025-05-26T00:00:00',
  '2025-08-25T00:00:00',
  '2025-12-25T00:00:00',
  '2025-12-26T00:00:00',
  '2026-01-01T00:00:00',
  '2026-04-03T00:00:00',
  '2026-04-06T00:00:00',
  '2026-05-04T00:00:00',
  '2026-05-25T00:00:00',
  '2026-08-31T00:00:00',
  '2026-12-25T00:00:00',
  '2026-12-28T00:00:00',
  '2027-01-01T00:00:00',
  '2027-03-26T00:00:00',
  '2027-03-29T00:00:00',
  '2027-05-03T00:00:00',
  '2027-05-31T00:00:00',
  '2027-08-30T00:00:00',
  '2027-12-27T00:00:00',
  '2027-12-28T00:00:00',
];

export const checkIsDateBankHoliday = (date: string) =>
  !!bankHolidays.filter((item) => dayjs(item).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')).length;

export const checkIsDateBankHolidayOrWeekend = (date: string) => {
  const dayName = dayjs(date).format('dddd').toLocaleLowerCase();
  return checkIsDateBankHoliday(date) || dayName === 'saturday' || dayName === 'sunday';
};

export const findNextPossibleWorkingDay = (date: string): { date: string; dayName: string } => {
  const nextDay = dayjs(date).add(1, 'day');
  const nextPossibleWorkingDay = {
    date: nextDay.format('YYYY-MM-DD'),
    dayName: nextDay.format('dddd').toLocaleLowerCase(),
  };

  if (
    nextPossibleWorkingDay.dayName === 'saturday' ||
    nextPossibleWorkingDay.dayName === 'sunday' ||
    checkIsDateBankHoliday(nextPossibleWorkingDay.date)
  ) {
    return findNextPossibleWorkingDay(nextPossibleWorkingDay.date);
  }
  return nextPossibleWorkingDay;
};
